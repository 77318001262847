<template>
  <div class="invoice--container">
    <div class="header--action" v-if="$route.query.mobile !== 'true'">
      <div class="row align-items-center">
        <div class="col">{{ this.document ? this.document.invoice_number : 'Invoice' }}</div>
        <div class="col-auto">
          <button class="btn btn-primary btn-block" @click="print">
            {{ $t('invoice.print') }}
          </button>
        </div>
      </div>
    </div>
    <div
      class="container p-4 my-4"
      :style="{
        'margin-top': headerHeight + 'px',
      }"
    >
      <invoice v-if="$route.name === 'invoice'"></invoice>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
const Invoice = () => import('@/components/transaction-document/invoice');

export default {
  head() {
    return {
      title: this.document ? this.document.invoice_number : 'Invoice',
    };
  },
  components: {
    Invoice,
  },
  computed: {
    ...mapState({
      headerHeight: state => state.size.headerHeight,
      document: state => state.v2.booking.detail.transactionDocument.document,
    }),
  },
  methods: {
    print() {
      window.print();
    },
  },
};
</script>
